import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { usePin } from '@/hooks/usePin';

const Index = () => {
  const router = useRouter();
  const { apiKey, uid } = usePin();
  useEffect(() => {
    if (apiKey && uid) {
      router.push('/pedidos');
    } else {
      router.push('/login');
    }
  });
  return <div />;
};

export default Index;
